<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-tag-multiple</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Inventar</v-toolbar-title>
      <v-spacer />

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        clearable
        id="search"
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab v-for="tab in tabs" :key="tab.title" :to="tab.to">
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <router-view :search="search"></router-view>
  </v-container>
</template>

<script>
export default {
  name: 'Inventory',

  data: () => ({
    search: '',
    tabs: [
      {
        title: 'Geräte',
        icon: 'mdi-tag',
        to: { name: 'InventoryDevices' },
      },
      {
        title: 'Art',
        icon: 'mdi-laptop',
        to: { name: 'InventoryDeviceTypes' },
      },
      {
        title: 'Modell',
        icon: 'mdi-cube',
        to: { name: 'InventoryBrandModels' },
      },

      {
        title: 'Firmen',
        icon: 'mdi-card-account-details',
        to: { name: 'InventoryCompanies' },
      },
      {
        title: 'Reparaturen',
        icon: 'mdi-screwdriver',
        to: { name: 'InventoryDeviceServices' },
      },
      {
        title: 'Ausleihe',
        icon: 'mdi-bag-suitcase',
        to: { name: 'InventoryDeviceLoans' },
      },
    ],
  }),
};
</script>